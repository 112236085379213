<template>
  <!-- eslint-disable -->
  <h1>{{ title }}</h1>
  <CustomCard
    v-for="(item, i) in urls"
    :key="i"
    :item="item"
    :title="title"
    :branch="branch"
  />
</template>

<script>
import CustomCard from "../components/customCard";
import store from "../store";
import { onMounted, ref } from "vue";
import { db } from "../firebase/config";
export default {
  components: { CustomCard },
  props: ["branch"],
  setup(props) {
    const urls = ref([]);
    const title = ref("");
    const allTitles = store.state.topics;
    onMounted(async () => {
      title.value = allTitles.find((e) => e.branch === props.branch).title;
      const all2 = await db
        .collection("branches")
        .doc(props.branch)
        .collection("items")
        .get();
      all2.docs.forEach((doc, i) => {
        const item = doc.data();
        urls.value = [
          ...urls.value,
          {
            available: item.available,
            color: item.color,
            description: item.description,
            downloadURL: item.downloadURL,
            name: item.name,
            note: item.note,
            path: item.path,
            price: item.price,
            sizes: item.sizes,
          },
        ];
      });
      try {
        store.dispatch("customPicsA");
      } catch (err) {}
    });
    const buyNow = ref(store.state.customize);
    return { buyNow, urls, title };
  },
};
</script>

<style scoped></style>
