<template>
  <!-- eslint-disable -->
  <div class="mini">
    <img :src="item.downloadURL" alt="toto" />
    <div style="margin-top: 20px">
      <Button label="Details" class="p-button-warning" @click="single" />
      <Button label="Add to Cart" class="p-button-warning" @click="cart" />
    </div>
  </div>
  <Dialog header="Cart" v-model:visible="dispalyDialog">
    <h3>Item Added to the Cart</h3>
    <Button label="Go to Cart" class="p-button-warning" @click="go" />
    <Button
      label="Close"
      class="p-button-danger"
      @click="dispalyDialog = false"
    />
  </Dialog>
</template>

<script>
import { ref } from "@vue/reactivity";
import { useRouter } from "vue-router";
import addToCart from "../modules/addToCart";
import { auth, db } from "../firebase/config";
import { onMounted } from "@vue/runtime-core";
export default {
  props: ["item", "title", "branch"],
  setup(props) {
    const router = useRouter();
    const dispalyDialog = ref(false);
    const cart = async () => {
      if (auth.currentUser) {
        const { addToCartError } = await addToCart(
          props.title,
          props.item.name,
          props.item.downloadURL,
          "none",
          "none"
        );
        if (!addToCartError.value) {
          dispalyDialog.value = true;
        }
      } else {
        window.alert("Please Signup or Login first");
      }
    };
    const single = async () => {
      const query = await db
        .collection("branches")
        .doc(props.branch)
        .collection("items")
        .where("path", "==", props.item.path)
        .get();
      const id = query.docs.map((doc) => {
        return doc.id
      })[0]
      router.push({
        name: "singleCustom",
        params: {
          available: props.item.available,
          color: props.item.color,
          description: props.item.description,
          downloadURL: props.item.downloadURL,
          name: props.item.name,
          note: props.item.note,
          path: props.item.path,
          price: props.item.price,
          sizes: props.item.sizes,
          title: props.title,
          branch: props.branch,
          id: id,
        },
      });
    };
    const go = () => {
      router.push({ name: "cart" });
    };
    onMounted(() => {});
    return { cart, single, dispalyDialog, go };
  },
};
</script>

<style scoped>
.mini {
  display: inline-block;
  background: white;
  border: solid black 2px;
  border-radius: 10px;
  margin: 10px;
  padding-bottom: 10px;
  text-align: center;
  width: 15%;
  height: auto;
}
img {
  width: 100%;
}
.price {
  font-weight: bold;
  font-size: 20px;
  color: rgb(161, 100, 26);
}
.p-button-warning {
  margin: 0 5px 5px 5px;
}
@media (max-width: 1230px) {
  .mini {
    width: 23%;
  }
}
@media (max-width: 1020px) {
  .mini {
    width: 30%;
  }
}
@media (max-width: 620px) {
  .mini {
    width: 45%;
  }
}
@media (max-width: 420px) {
  .mini {
    width: 95%;
  }
}
</style>
